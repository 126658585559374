import type { ComponentType } from "react"
import _oqyjo, { useRef as _f08zp } from "react"
import {
    extractShortcutKey as _8qgrn,
    hasCommandShortcut as _u45un,
    hasShiftShortcut as _ii6bn,
    triggerClick as _fcpgy,
    useFocus as _l2avb,
    useShortcutKey as _e50gc,
    useUnload as _rdggb,
} from "https://framer.com/m/Shothelpers-ir14.js"

export function withShortcut(_t0t8p): ComponentType {
    return (_t7f5u) => {
        const _30y32 = _f08zp(null)
        const _ozudm = {
            shortcutKey: _8qgrn(_t7f5u.name),
            hasCmd: _u45un(_t7f5u.name),
            hasShift: _ii6bn(_t7f5u.name),
        }
        _e50gc(_ozudm, () => {
            _fcpgy(_30y32)
        })
        _rdggb()
        _l2avb()
        return (
            <span ref={_30y32}>
                <_t0t8p {..._t7f5u} />
            </span>
        )
    }
}
